import ActionTypes from "./ActionTypes";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const Submit = (form) => async (dispatch) => {
  dispatch({ type: ActionTypes.SUBMIT_FORM_REQUEST });
  try {
    await addDoc(collection(getFirestore(), "contactForm"), {
      ...form,
      createdAt: new Date(),
    });
    dispatch({ type: ActionTypes.SUBMIT_FORM_SUCCESS, payload: form });
  } catch (err) {
    dispatch({ type: ActionTypes.SUBMIT_FORM_FAIL, payload: err.message });
  }
};

const SubscribeNewsletter = (email) => async (dispatch) => {
  dispatch({ type: ActionTypes.SUBSCRIBE_NEWSLETTER_REQUEST });
  try {
    await addDoc(collection(getFirestore(), "newsLetter"), { email });
    dispatch({
      type: ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
      payload: email,
    });
  } catch (err) {
    dispatch({
      type: ActionTypes.SUBSCRIBE_NEWSLETTER_FAIL,
      payload: err.message,
    });
  }
};

export { Submit, SubscribeNewsletter };
