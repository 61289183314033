import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState, useEffect } from "react";
import "./css/index.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const next = useCallback(() => {
    let images = document.querySelectorAll(".slide_item");

    if (activeSlide + 1 === images.length) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }, [activeSlide]);

  const previous = () => {
    let images = document.querySelectorAll(".slide_item");

    if (activeSlide === 0) {
      setActiveSlide(images.length - 1);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  };

  useEffect(() => {
    setTimeout(next, 10000);
  }, [next]);

  return (
    <div className="slider">
      <div className="slides">
        <div className={`slide_item ${activeSlide === 0 && "active"}`}>
          <img loading="lazy" src="/images/banner1.jpg" alt="banner" />
          <div className="image_overlay"></div>
          <div className="slide_caption">
            <div className="container">
              <h1>Problem solver</h1>
              <p>
                Every problem has a solution with us. Many experienced
                developers are here
                <br />
                to solve your problems.
              </p>
              <a href="#contactus">
                <button>get a quote</button>
              </a>
            </div>
          </div>
        </div>

        <div className={`slide_item ${activeSlide === 1 && "active"}`}>
          <img loading="lazy" src="/images/banner2.jpg" alt="banner" />
          <div className="image_overlay"></div>
          <div className="slide_caption">
            <div className="container">
              <h1>Website development</h1>
              <p>
                Websites promote you 24/7, No employee will do that. Your
                website is the
                <br />
                center of your digital ecosystem.
              </p>
              <a href="#contactus">
                <button>get a quote</button>
              </a>
            </div>
          </div>
        </div>

        <div className={`slide_item ${activeSlide === 2 && "active"}`}>
          <img loading="lazy" src="/images/banner3.jpg" alt="banner" />
          <div className="image_overlay"></div>
          <div className="slide_caption">
            <div className="container">
              <h1>Mobile Applications</h1>
              <p>
                Mobiles will affect more than just your digital operations — it
                will transform
                <br />
                your entire business.
              </p>
              <a href="#contactus">
                <button>get a quote</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="slider_controlls">
        <button className="slider_previous_controll" onClick={previous}>
          <FontAwesomeIcon icon={faChevronLeft} className="slider_icons" />
        </button>
        <button className="slider_next_controll" onClick={next}>
          <FontAwesomeIcon icon={faChevronRight} className="slider_icons" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
