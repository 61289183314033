import { faBitcoin, faGoogleWallet } from "@fortawesome/free-brands-svg-icons";
import {
  faCode,
  faEdit,
  faMobileAlt,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { HeaderImage, Contact } from "./../../Components";
import "./css/index.css";
import { Helmet } from "react-helmet";

const WebsiteDevelopment = () => {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".wipe_right_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".our_web_services_row").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".our_web_services_row")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".our_web_services_row")
          ?.classList.remove("visible");
      }
    };
  });

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Web design is one of the essential things we consider in our design lab for websites when developing a brand to outdo the competition. With the majority of the population well-informed to use the internet, it's essential to make sure your company is also. Lucky for you, at Mellontical solutions, we provide custom and expert web design solutions to your company. Websites aren't something new at this time However, you should have the right knowledge before you begin developing one. Mellontical solutions are available to handle this complicated business and not make you a burden."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, website development, custom dynamic platforms website, cryptocurrency website, responsive designs, business & e-commerce, wallet integration, react, node, mongodb, express, javascript, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, graphic designing, brand management, digital marketing, search engine optimization, seo, mobile app development, best software houses"
        />
        <title>
          Website Development | Mellontical Solutions | Software Company
        </title>
      </Helmet>
      <HeaderImage
        headerContent="Website development"
        headerImage="/images/webdev.jpg"
      />
      <div className="container">
        <div className="one_service_row">
          <div className="one_service_col wipe_right_one">
            <img
              src="/images/webdevservice.jpg"
              loading="lazy"
              alt="website development"
            />
          </div>
          <div className="one_service_col wipe_left_one">
            <p>
              Web design is one of the essential things we consider in our
              design lab for websites when developing a brand to outdo the
              competition. With the majority of the population well-informed to
              use the internet, it's essential to make sure your company is
              also. Lucky for you, at Mellontical solutions, we provide custom
              and expert web design solutions to your company. Websites aren't
              something new at this time However, you should have the right
              knowledge before you begin developing one. Mellontical solutions
              are available to handle this complicated business and not make you
              a burden.
            </p>
          </div>
        </div>
        <div className="web_services">
          <h1 className="website_service_heading">website services</h1>
          <div className="our_web_services_row">
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faEdit} className="card_icon" />
                </div>
                <h3>custom dynamic platforms</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faBitcoin} className="card_icon" />
                </div>
                <h3>cryptocurrency website</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faMobileAlt} className="card_icon" />
                </div>
                <h3>responsive designs</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="card_icon"
                  />
                </div>
                <h3>business & e-commerce</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faGoogleWallet}
                    className="card_icon"
                  />
                </div>
                <h3>wallet integration</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faCode} className="card_icon" />
                </div>
                <h3>react, node, mongodb</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default WebsiteDevelopment;
