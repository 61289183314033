import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB3EcHOsSVo254L-wgvxPkNKHnrzseaYiM",
  authDomain: "mellonticalsolution.firebaseapp.com",
  projectId: "mellonticalsolution",
  storageBucket: "mellonticalsolution.appspot.com",
  messagingSenderId: "592563982417",
  appId: "1:592563982417:web:7e5bb91d7519c4dc6a2870",
  measurementId: "G-B9C557W382",
};

const app = initializeApp(firebaseConfig);

getAnalytics(app);

export default app;
