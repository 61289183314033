import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./css/index.css";

const NotFoud = () => {
  return (
    <div className="not_found">
      <FontAwesomeIcon icon={faSearch} className="search_icon" />
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFoud;
