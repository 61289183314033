import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import "./css/index.css";

const TopButton = () => {
  const gototop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document && document.querySelector(".top") && window.scrollY > 1200) {
        document.querySelector(".top").style.display = "block";
      } else if (document && document.querySelector(".top")) {
        document.querySelector(".top").style.display = "none";
      }
    });
  });

  return (
    <div className="top" onClick={gototop}>
      <FontAwesomeIcon icon={faChevronUp} className="top_icon" />
    </div>
  );
};

export default TopButton;
