import { useEffect } from "react";
import { Contact, HeaderImage } from "../../Components";
import { Helmet } from "react-helmet";

const SearchEngineOptimization = () => {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".wipe_right_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".our_web_services_row").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".our_web_services_row")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".our_web_services_row")
          ?.classList.remove("visible");
      }
    };
  });

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="SEO is the process of creating a strong and unique brand for a website. Without SEO, it’s hard to say how we would find websites, businesses, or the information we seek daily. The search engine results would be too easy to manipulate, and as users, we would have to look much longer and harder to find valuable sites instead of simple results to get to the first page. SEO has a vital role because it ensures that typical people don’t have to search for more than a few seconds to find the information or products they want. It's is a process that takes a website from a non-searchable to a searchable."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, search engine optimization, seo, google adwords, google adsense, serach optimization, track visitors, seo keywords, google search console, google analytics, google keywordplanner, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, graphic designing, brand management, digital marketing, website development, mobile app development, best software houses"
        />
        <title>
          Search Engine Optimization (SEO) | Mellontical Solutions | Software
          Company
        </title>
      </Helmet>
      <HeaderImage
        headerImage="/images/seo.jpg"
        headerContent="Search Engine Optimization"
      />
      <div className="container">
        <div className="one_service_row">
          <div className="one_service_col wipe_right_one">
            <img src="/images/seoservice.jpg" loading="lazy" alt="search engine optimization (SEO)" />
          </div>
          <div className="one_service_col wipe_left_one">
            <p>
              SEO is the process of creating a strong and unique brand for a
              website. Without SEO, it’s hard to say how we would find websites,
              businesses, or the information we seek daily. The search engine
              results would be too easy to manipulate, and as users, we would
              have to look much longer and harder to find valuable sites instead
              of simple results to get to the first page. SEO has a vital role
              because it ensures that typical people don’t have to search for
              more than a few seconds to find the information or products they
              want. It's is a process that takes a website from a non-searchable
              to a searchable.
            </p>
          </div>
        </div>
        <div className="web_services">
          <h1 className="website_service_heading">seo services</h1>
          <div className="our_web_services_row">
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <img src="/images/ads.png" alt="google adwords" className="card_icon" />
                </div>
                <h3>google adwords</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <img src="/images/adsense.png" alt="google adsense" className="card_icon" />
                </div>
                <h3>google adsense</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <img
                    src="/images/optimization.png"
                    alt="search optimization"
                    className="card_icon"
                  />
                </div>
                <h3>search optimization</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <img src="/images/visitor.png" alt="track visitors" className="card_icon" />
                </div>
                <h3>track visitors</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <img src="/images/keyword.png" alt="SEO keywords" className="card_icon" />
                </div>
                <h3>SEO keywords</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <img
                    src="/images/analytics.png"
                    alt="google search console, google analytics"
                    className="card_icon"
                  />
                </div>
                <h3>google search console, google analytics</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default SearchEngineOptimization;
