import { useEffect, useState } from "react";
import { Submit } from "../../Store/Actions";
import "./css/index.css";
import { useDispatch, useSelector } from "react-redux";

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const [error, setError] = useState();

  const { response } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const submit = () => {
    if (
      form.name !== "" &&
      form.email !== "" &&
      form.number !== "" &&
      form.message !== ""
    ) {
      dispatch(Submit(form));
      setError();
    } else {
      setError("Please fill all the fields");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (
        document.querySelector(".contact_form") &&
        document.querySelector(".contact_form").getBoundingClientRect().top -
          window.innerHeight <
          -100
      ) {
        document.querySelector(".contact_form")?.classList.add("visible");
      } else {
        document.querySelector(".contact_form")?.classList.remove("visible");
      }

      if (
        document.querySelector(".contact_header_heading") &&
        document
          .querySelector(".contact_header_heading")
          .getBoundingClientRect().top -
          window.innerHeight <
          -100
      ) {
        document
          .querySelector(".contact_header_heading")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".contact_header_heading")
          ?.classList.remove("visible");
      }
    });
  });

  useEffect(() => {
    if (response && response.name) {
      setForm({
        name: "",
        email: "",
        number: "",
        message: "",
      });
      setTimeout(
        () => dispatch({ type: "SUBMIT_FORM_SUCCESS", payload: null }),
        3000
      );
    }
  }, [response, dispatch]);

  return (
    <div className="contact" id="contactus">
      <div className="contact_header">
        <div className="contact_image_overlay"></div>
        <h1 className="contact_header_heading">don't hesitate to contact us</h1>
      </div>
      <div className="container">
        <div className="contact_form">
          <div className="contact_col">
            <img loading="lazy" src="/images/contact.jpg" alt="contact us" />
            <div className="contact_col_image_overlay"></div>
          </div>
          <div className="contact_col">
            <div className="container">
              <div className="form">
                <div className="form_row">
                  {response ? (
                    <div
                      className="form_col"
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#4BB543",
                        borderRadius: 3,
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 5px",
                          fontSize: 16,
                        }}
                      >
                        Your response has been added
                      </p>
                    </div>
                  ) : null}

                  {error ? (
                    <div
                      className="form_col"
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#FF0000",
                        borderRadius: 3,
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 5px",
                          fontSize: 16,
                        }}
                      >
                        {error}
                      </p>
                    </div>
                  ) : null}
                  <div className="form_col">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Your Name"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form_col">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Your Email"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="form_col">
                    <label htmlFor="number">Phone Number</label>
                    <input
                      type="number"
                      id="number"
                      placeholder="Your Number"
                      value={form.number}
                      onChange={(e) =>
                        setForm({ ...form, number: e.target.value })
                      }
                    />
                  </div>
                  <div className="form_col">
                    <label htmlFor="details">Message</label>
                    <textarea
                      id="details"
                      cols="30"
                      rows="10"
                      placeholder="Your Message..."
                      value={form.message}
                      onChange={(e) =>
                        setForm({ ...form, message: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div className="form_col">
                    <button onClick={submit}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
