import "./css/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCopyright } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubscribeNewsletter } from "../../Store/Actions";

const Footer = () => {
  const [email, setEmail] = useState();

  const { successEmail } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const submitEmail = (e) => {
    e.preventDefault();
    dispatch(SubscribeNewsletter(email));
  };

  useEffect(() => {
    if (successEmail) {
      setEmail("");
    }
  }, [successEmail]);

  return (
    <div className="footer">
      <div className="container">
        <div className="footer_row">
          <div className="footer_col">
            <img
              loading="lazy"
              src="/images/mellonticalsolutionswhitelogo.png"
              alt="footer logo"
            />
            <p>
              Mellontical Solutions provides you with assistance to help your
              company's social expansion.
            </p>
          </div>
          <div className="footer_col">
            <h1>Services</h1>
            <ul>
              <li>
                <Link to="/services/websitedevelopment">
                  <FontAwesomeIcon icon={faChevronRight} /> Website Development
                </Link>
              </li>
              <li>
                <Link to="/services/mobileappdevelopment">
                  <FontAwesomeIcon icon={faChevronRight} /> Mobile App
                  Development
                </Link>
              </li>
              <li>
                <Link to="/services/searchengineoptimization">
                  <FontAwesomeIcon icon={faChevronRight} /> Search Engine
                  Optimization (SEO)
                </Link>
              </li>
              <li>
                <Link to="/services/graphicdesigning">
                  <FontAwesomeIcon icon={faChevronRight} /> Graphic Designing
                </Link>
              </li>
              <li>
                <Link to="/services/brandmanagement">
                  <FontAwesomeIcon icon={faChevronRight} /> Brand Management
                </Link>
              </li>
              <li>
                <Link to="/services/digitalmarketing">
                  <FontAwesomeIcon icon={faChevronRight} /> Digital Marketing
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer_col">
            <div className="footer_col">
              <h1>Contact</h1>
              <ul>
                <li>
                  <a
                    href="mailto:contact@mellonticalsolutions.com"
                    target="_mail"
                  >
                    contact@mellonticalsolutions.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://api.whatsapp.com/send?phone=12342559855"
                    target="_whatsapp"
                  >
                    +1 (234) 255-9855
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer_col">
              <h1>Follow Us</h1>
              <ul className="footer_social_links_list">
                <li className="footer_social_links">
                  <a
                    href="https://www.facebook.com/mellonticalsolutions"
                    target="_facebook"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className="footer_social_links">
                  <a
                    href="https://www.linkedin.com/company/mellonticalsolutions"
                    target="_linkedin"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li className="footer_social_links">
                  <a href="https://twitter.com/mellontical" target="_twitter">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_col">
            <h1>Newsletter</h1>
            {successEmail ? (
              <div
                style={{
                  color: "#ffffff",
                  backgroundColor: "#4BB543",
                  borderRadius: 3,
                  marginBottom: -25,
                }}
              >
                <p
                  style={{
                    padding: "10px 5px",
                    fontSize: 16,
                  }}
                >
                  Thanks for subscribe
                </p>
              </div>
            ) : null}
            <form method="post" onSubmit={submitEmail}>
              <input
                type="email"
                className="newsletter_input"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="newsletter_button">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="border_bottom"></div>
        <div className="second_footer_row">
          <div className="second_footer_col">
            Copyright <FontAwesomeIcon icon={faCopyright} /> mellontical
            solutions
          </div>
          <div className="second_footer_col">
            <Link to="/privacypolicy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
