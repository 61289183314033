import { faCheck, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { HeaderImage, Contact } from "./../../Components";
import "./css/index.css";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".about_us_heading").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".about_us_heading")?.classList.add("visible");
      } else {
        document
          .querySelector(".about_us_heading")
          ?.classList.remove("visible");
      }

      if (
        document.querySelector(".qoute").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".qoute")?.classList.add("visible");
      } else {
        document.querySelector(".qoute")?.classList.remove("visible");
      }

      if (
        document.querySelector(".contact_form").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".contact_form")?.classList.add("visible");
      } else {
        document.querySelector(".contact_form")?.classList.remove("visible");
      }

      if (
        document
          .querySelector(".contact_header_heading")
          .getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".contact_header_heading")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".contact_header_heading")
          ?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_two").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_two")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_two")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_three").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_three")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_three")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_four").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_four")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_four")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_five").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_five")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_five")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_six").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_six")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_six")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_right_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_right_two").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_two")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_two")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_right_three").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_three")?.classList.add("visible");
      } else {
        document
          .querySelector(".wipe_right_three")
          ?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_right_four").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_four")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_four")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_right_five").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_five")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_five")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_right_six").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_six")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_six")?.classList.remove("visible");
      }
    };
  });

  return (
    <div className="about">
      <Helmet>
        <meta
          name="description"
          content="Mellontical Solutions is a leading provider of high quality, high performance, and cost-effective solutions for the social growth of your small startup. We are a team of highly skilled and experienced professionals who are committed to providing the highest quality and cost-effective solutions for our clients."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, graphic designing, brand management, digital marketing, search engine optimization, seo, website development, mobile app development, best software houses"
        />
        <title>About | Mellontical Solutions | Software Company</title>
      </Helmet>
      <HeaderImage
        headerContent="about us"
        headerImage="/images/aboutHeader.jpg"
      />

      {/* ********** About us ********** */}
      <div className="container">
        <div className="about_us">
          <div className="about_col">
            <img loading="lazy" src="/images/aboutus.jpg" alt="about us" />
            <div className="qoute">
              <FontAwesomeIcon icon={faQuoteRight} className="quote_icon" />
              <p>
                We are committed to ensuring that the technology era has the
                best future imaginable.
              </p>
              <div className="quote_cone"></div>
            </div>
          </div>
          <div className="about_col">
            <h2 className="about_us_heading">about us</h2>
            <h1>Develop easy to use, attractive and low-cost softwares</h1>
            <p>
              Mellontical Solutions is a software company where there's always a
              collaborative team to serve you. We are providing our services to
              people globally. We aim to develop your dream software.
              Mellontical Solutions serves those who start a small physical
              business and want to grow their start-ups socially, and we also
              help those who have the passion for establishing an online store
              and don't have a physical location.
            </p>
            <ul className="about_list">
              <li>
                <FontAwesomeIcon className="tick_icon" icon={faCheck} /> We are
                not a company, We are a team.
              </li>
              <li>
                <FontAwesomeIcon className="tick_icon" icon={faCheck} /> We are
                a team of professionals.
              </li>
              <li>
                <FontAwesomeIcon className="tick_icon" icon={faCheck} /> We are
                passionate about what we do.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ********** About us end ********** */}

      {/* ********** Four questions ********** */}
      <div className="container">
        <div className="about_questions">
          <div className="question_row">
            <div className="question_col wipe_right_one">
              <img
                loading="lazy"
                src="/images/questionOne.jpg"
                alt="who we are?"
              />
            </div>
            <div className="question_col wipe_left_one">
              <h1>who we are?</h1>
              <p>
                Mellontical Solutions is a software company where there's always
                a collaborative team to serve you. We are providing our services
                to people globally for more than two years. We aim to develop
                your dream website.
              </p>
            </div>
          </div>
          <div className="question_row">
            <div className="question_col wipe_right_two">
              <h1>whom do we serve?</h1>
              <p>
                Mellontical Solutions serves those who start a small physical
                business and want to grow their start-ups socially, and we also
                help those who have the passion for establishing an online store
                and don't have a physical location.
              </p>
            </div>
            <div className="question_col wipe_left_two">
              <img
                loading="lazy"
                src="/images/questionTwo.jpg"
                alt="whom do we serve?"
              />
            </div>
          </div>
          <div className="question_row">
            <div className="question_col wipe_right_three">
              <img
                loading="lazy"
                src="/images/questionThree.jpg"
                alt="how are we different?"
              />
            </div>
            <div className="question_col wipe_left_three">
              <h1>how are we different?</h1>
              <p>
                Mellontical Solutions is very different because we know how to
                serve our customers and make them lifetime customers. We know
                how to develop a good relationship with clients, and we also
                know that quality is more important than quantity.
              </p>
            </div>
          </div>
          <div className="question_row">
            <div className="question_col wipe_right_four">
              <h1>what problems do we solve for our customers?</h1>
              <p>
                Mellontical Solutions solves the significant problem that
                everybody faced after starting a business is that from where we
                should build our website, how to bring it on google's first
                page?. So don't worry, we are here with the solution.
              </p>
            </div>
            <div className="question_col wipe_left_four">
              <img
                loading="lazy"
                src="/images/questionFour.jpg"
                alt="what problems do we solve for our customers?"
              />
            </div>
          </div>
          <div className="question_row">
            <div className="question_col wipe_right_five">
              <img
                loading="lazy"
                src="/images/value.jpg"
                alt="our need in market"
              />
            </div>
            <div className="question_col wipe_left_five">
              <h1>our need in market</h1>
              <p>
                Our company is a leading provider of high quality, high
                performance, and cost-effective solutions for the social growth
                of your small startup. We are a team of highly skilled and
                experienced professionals who are committed to providing the
                highest quality and cost-effective solutions for our clients.
              </p>
            </div>
          </div>
          <div className="question_row">
            <div className="question_col wipe_right_six">
              <h1>What is our aimbition?</h1>
              <p>
                We aim to develop modern solutions for your business to bring
                your business on the second step of success and to make your
                business more efficient and profitable. We are a team of highly
                skilled and experienced professionals who are here to help you
                achieve your goals.
              </p>
            </div>
            <div className="question_col wipe_left_six">
              <img
                src="/images/ourMission.jpg"
                loading="lazy"
                alt="What is our aimbition?"
              />
            </div>
          </div>
        </div>
      </div>
      {/* ********** Four questions end ********** */}

      {/* ********** Contact ********** */}
      <Contact />
      {/* ********** Contact end ********** */}
    </div>
  );
};

export default About;
