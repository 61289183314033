import ActionTypes from "../../Actions/ActionTypes";

const INITIAL_STATE = {
  response: null,
  error: null,
  successEmail: null,
};

const AppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_FORM_SUCCESS:
      return {
        response: action.payload,
      };

    case ActionTypes.SUBMIT_FORM_FAIL:
      return {
        error: action.payload,
      };

    case ActionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        successEmail: action.payload,
      };

    case ActionTypes.SUBSCRIBE_NEWSLETTER_FAIL:
      return {
        newLetterError: action.payload,
      };

    default:
      return state;
  }
};

export default AppReducer;
