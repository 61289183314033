import {
  faAddressCard,
  faBezierCurve,
  faBookOpen,
  faBoxOpen,
  faEnvelope,
  faPenNib,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { HeaderImage, Contact } from "./../../Components";
import { Helmet } from "react-helmet";

const GraphicDesigning = () => {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".wipe_right_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".our_web_services_row").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".our_web_services_row")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".our_web_services_row")
          ?.classList.remove("visible");
      }
    };
  });

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="It is a fact that the world is moving through art, and transforming this art into digital art is a trend that is happening today. We're of the opinion to provide you with our online graphic design services to make your company prosperous. It will be successful only if you give it a physical appearance and this can be achieved when you begin investing in digital art. You will be able to meet the challenges you face today. This art piece is more like crutches that help to move forward when you are unable to find a way to move forward, never creating a reason to never let yourself slide."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, graphic designing, custom logos & mockups, business cards & letterheads, flyers & brochures, catalogues & envelopes, packaging designs & documents, photoshop, illustrator, xd, figma, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, brand management, digital marketing, search engine optimization, seo, website development, mobile app development, best software houses"
        />
        <title>
          Graphic Designing | Mellontical Solutions | Software Company
        </title>
      </Helmet>
      <HeaderImage
        headerImage="/images/graphicDesigning.jpg"
        headerContent="Graphic Designing"
      />
      <div className="container">
        <div className="one_service_row">
          <div className="one_service_col wipe_right_one">
            <img src="/images/graphicservice.jpg" loading="lazy" alt="graphic designing" />
          </div>
          <div className="one_service_col wipe_left_one">
            <p>
              It is a fact that the world is moving through art, and
              transforming this art into digital art is a trend that is
              happening today. We're of the opinion to provide you with our
              online graphic design services to make your company prosperous. It
              will be successful only if you give it a physical appearance and
              this can be achieved when you begin investing in digital art. You
              will be able to meet the challenges you face today. This art piece
              is more like crutches that help to move forward when you are
              unable to find a way to move forward, never creating a reason to
              never let yourself slide.
            </p>
          </div>
        </div>
        <div className="web_services">
          <h1 className="website_service_heading">
            graphic designing services
          </h1>
          <div className="our_web_services_row">
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faPenNib} className="card_icon" />
                </div>
                <h3>custom logos & mockups</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faAddressCard} className="card_icon" />
                </div>
                <h3>business cards & letterheads</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faBookOpen} className="card_icon" />
                </div>
                <h3>flyers & brochures</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope} className="card_icon" />
                </div>
                <h3>catalogues & envelopes</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faBoxOpen} className="card_icon" />
                </div>
                <h3>packaging designs & documents</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faBezierCurve} className="card_icon" />
                </div>
                <h3>photoshop, illustrator, xd</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default GraphicDesigning;
