import AppRouter from "./Config/Router";
import { Provider } from "react-redux";
import Store from "./Store";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Provider store={Store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
