import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeaderImage } from "../../Components";
import { Submit } from "../../Store/Actions";
import "./css/index.css";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const { response } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(Submit(form));
  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".contact_form_col h2").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".contact_form_col h2")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".contact_form_col h2")
          ?.classList.remove("visible");
      }
    };
  });

  useEffect(() => {
    if (response) {
      setForm({
        name: "",
        email: "",
        number: "",
        message: "",
      });
      setTimeout(
        () => dispatch({ type: "SUBMIT_FORM_SUCCESS", payload: null }),
        3000
      );
    }
  }, [response, dispatch]);

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Mellontical Solutions is a leading provider of high quality, high performance, and cost-effective solutions for the social growth of your small startup. We are a team of highly skilled and experienced professionals who are committed to providing the highest quality and cost-effective solutions for our clients."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, graphic designing, brand management, digital marketing, search engine optimization, seo, website development, mobile app development, best software houses"
        />
        <title>Contact | Mellontical Solutions | Software Company</title>
      </Helmet>
      <HeaderImage
        headerContent="contact us"
        headerImage="/images/contactHeader.jpg"
      />
      {/* Contact us */}
      <div className="container">
        <div className="contact_form_row">
          <form
            className="contact_form_col"
            method="post"
            onSubmit={submitForm}
          >
            {response ? (
              <div
                className="form_col"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#4BB543",
                  borderRadius: 3,
                }}
              >
                <p
                  style={{
                    padding: "10px 5px",
                    fontSize: 16,
                  }}
                >
                  Your response has been added
                </p>
              </div>
            ) : null}
            <div className="form_col">
              <label htmlFor="name">Name</label>
              <input
                required
                type="text"
                id="name"
                placeholder="Your Name"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </div>
            <div className="form_col">
              <label htmlFor="email">Email</label>
              <input
                required
                type="email"
                id="email"
                placeholder="Your Email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </div>
            <div className="form_col">
              <label htmlFor="number">Phone Number</label>
              <input
                required
                type="number"
                id="number"
                placeholder="Your Number"
                value={form.number}
                onChange={(e) => setForm({ ...form, number: e.target.value })}
              />
            </div>
            <div className="form_col">
              <label htmlFor="details">Message</label>
              <textarea
                required
                id="details"
                cols="30"
                rows="10"
                placeholder="Your Message..."
                value={form.message}
                onChange={(e) => setForm({ ...form, message: e.target.value })}
              ></textarea>
            </div>
            <div className="form_col">
              <button>Submit</button>
            </div>
          </form>
          <div className="contact_form_col">
            <h2>contact us</h2>
            <h1>Get in touch with us</h1>
            <p>
              Fill the form and tell us about your project, your query or give
              us your feedback.
            </p>
            <ul>
              <li>
                <div className="contact_detail_row">
                  <div className="contact_detail_col">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="contact_detail_icon"
                    />
                  </div>
                  <div className="contact_detail_col">
                    <h3>email information</h3>
                    <p>
                      <a
                        href="mailto:contact@mellonticalsolutions.com"
                        target="_mail"
                      >
                        contact@mellonticalsolutions.com
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="contact_detail_row">
                  <div className="contact_detail_col">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="contact_detail_icon"
                    />
                  </div>
                  <div className="contact_detail_col">
                    <h3>calling support</h3>
                    <p>
                      <a
                        href="https://api.whatsapp.com/send?phone=12342559855"
                        target="_whatsapp"
                      >
                        +1 (234) 255-9855
                      </a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="contact_social_detail">
              <h2>follow us on social media</h2>
              <ol className="contact_social_links">
                <li>
                  <a
                    href="https://www.facebook.com/mellonticalsolutions"
                    target="_facebook"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/mellonticalsolutions"
                    target="_linkedin"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/mellontical" target="_twitter">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* Contact us end */}
    </div>
  );
};

export default Contact;
