import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./css/index.css";

const Header = () => {
  const { pathname } = useLocation();

  const openMenu = () => {
    document.querySelector(".nav_list").classList.toggle("active");
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      var navbar = document.querySelector(".second_navbar");
      var sticky = 200;
      if (navbar && window.pageYOffset > sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar && navbar.classList.remove("sticky");
      }
    });
  });

  useEffect(() => {
    document.querySelector(".nav_list").classList.remove("active");
  }, [pathname]);

  return (
    <div className="header">
      <div className="second_navbar">
        <div className="container">
          <div className="second_navbar_info">
            <Link to="/" className="logo">
              <img
                loading="lazy"
                src="/images/mellonticalsolutions.png"
                alt="logo"
              />
            </Link>

            <div className="navbar_list">
              <ul className="nav_list">
                <li className="nav_links">
                  <NavLink
                    exact
                    style={(isActive) => ({
                      color: isActive && "#ffd700",
                    })}
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav_links">
                  <NavLink
                    style={(isActive) => ({
                      color: isActive && "#ffd700",
                    })}
                    to="/about"
                  >
                    About us
                  </NavLink>
                </li>
                <li className="nav_links">
                  <span
                    style={{
                      color: pathname.includes("services") && "#ffd700",
                    }}
                    className="open_sub_list"
                  >
                    Services <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                  <div className="sub_list_section">
                    <NavLink
                      style={(isActive) => ({
                        backgroundColor: isActive && "#ffd700",
                      })}
                      to="/services/websitedevelopment"
                    >
                      Website development
                    </NavLink>
                    <NavLink
                      style={(isActive) => ({
                        backgroundColor: isActive && "#ffd700",
                      })}
                      to="/services/mobileappdevelopment"
                    >
                      Mobile app development
                    </NavLink>
                    <NavLink
                      style={(isActive) => ({
                        backgroundColor: isActive && "#ffd700",
                      })}
                      to="/services/searchengineoptimization"
                    >
                      Search engine opimization (SEO)
                    </NavLink>
                    <NavLink
                      style={(isActive) => ({
                        backgroundColor: isActive && "#ffd700",
                      })}
                      to="/services/graphicdesigning"
                    >
                      Graphic designing
                    </NavLink>
                    <NavLink
                      style={(isActive) => ({
                        backgroundColor: isActive && "#ffd700",
                      })}
                      to="/services/brandmanagement"
                    >
                      Brand management
                    </NavLink>
                    <NavLink
                      style={(isActive) => ({
                        backgroundColor: isActive && "#ffd700",
                      })}
                      to="/services/digitalmarketing"
                    >
                      Digital marketing
                    </NavLink>
                  </div>
                </li>
                {/* <li className="nav_links">
                  <Link to="/portfolio">Portfolio</Link>
                </li> */}
                <li className="nav_links">
                  <NavLink
                    style={(isActive) => ({
                      color: isActive && "#ffd700",
                    })}
                    to="/contactus"
                  >
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </div>
            <button className="hamburger" onClick={openMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
