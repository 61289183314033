import {
  faClock,
  faHandshake,
  faLightbulb,
  faList,
  faPaintBrush,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Contact, HeaderImage } from "../../Components";
import { Helmet } from "react-helmet";

const BrandManagement = () => {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".wipe_right_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".our_web_services_row").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".our_web_services_row")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".our_web_services_row")
          ?.classList.remove("visible");
      }
    };
  });

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Brand management and Branding Solutions is the analysis and plan on how the brand is perceived by the marketplace. Our team of experts in brand development as well as examiners and plan experts love to share your brand's story using the help of notable branding solutions. We design characters that will last forever and may help your company to grow into a recognizable name. The characters we create a strategy to make a lasting impression on the minds of your intended audience, helping you create an effect that lasts forever. Share your brand's personality development ideas with us now."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, brand management, strategic branding, creative ideas, timely execution, brand identity, attributes & positioning, reputation, visiblity, trust building, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, graphic designing, digital marketing, search engine optimization, seo, website development, mobile app development, best software houses"
        />
        <title>
          Brand Management | Mellontical Solutions | Software Company
        </title>
      </Helmet>
      <HeaderImage
        headerContent="brand management"
        headerImage="/images/brandmanagement.jpg"
      />
      <div className="container">
        <div className="one_service_row">
          <div className="one_service_col wipe_right_one">
            <img src="/images/brandservice.jpg" loading="lazy" alt="brand management" />
          </div>
          <div className="one_service_col wipe_left_one">
            <p>
              Brand management and Branding Solutions is the analysis and plan
              on how the brand is perceived by the marketplace. Our team of
              experts in brand development as well as examiners and plan experts
              love to share your brand's story using the help of notable
              branding solutions. We design characters that will last forever
              and may help your company to grow into a recognizable name. The
              characters we create a strategy to make a lasting impression on
              the minds of your intended audience, helping you create an effect
              that lasts forever. Share your brand's personality development
              ideas with us now.
            </p>
          </div>
        </div>
        <div className="web_services">
          <h1 className="website_service_heading">brand management services</h1>
          <div className="our_web_services_row">
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faLightbulb} className="card_icon" />
                </div>
                <h3>strategic branding</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faPaintBrush} className="card_icon" />
                </div>
                <h3>creative ideas</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faClock} className="card_icon" />
                </div>
                <h3>timely execution</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faTag} className="card_icon" />
                </div>
                <h3>brand identity</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faList} className="card_icon" />
                </div>
                <h3>attributes & positioning</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faHandshake} className="card_icon" />
                </div>
                <h3>reputation, visiblity, trust building</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default BrandManagement;
