const ActionTypes = {
  SUBMIT_FORM_REQUEST: "SUBMIT_FORM_REQUEST",
  SUBMIT_FORM_SUCCESS: "SUBMIT_FORM_SUCCESS",
  SUBMIT_FORM_FAIL: "SUBMIT_FORM_FAIL",
  SUBSCRIBE_NEWSLETTER_REQUEST: "SUBSCRIBE_NEWSLETTER_REQUEST",
  SUBSCRIBE_NEWSLETTER_SUCCESS: "SUBSCRIBE_NEWSLETTER_SUCCESS",
  SUBSCRIBE_NEWSLETTER_FAIL: "SUBSCRIBE_NEWSLETTER_FAIL",
};

export default ActionTypes;
