import React from "react";
import { Route, Switch } from "react-router-dom";
import { Footer, Header, TopButton } from "../../Components";
import {
  Home,
  About,
  Contact,
  NotFound,
  PrivacyPolicy,
  WebsiteDevelopment,
  MobileAppDevelopment,
  GraphicDesigning,
  BrandManagement,
  DigitalMarketing,
} from "../../Pages";
import SearchEngineOptimization from "../../Pages/SearchEngineOptimization";

// ************* Router ************
const AppRouter = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        {/* <Route exact path="/portfolio" component={Portfolio} /> */}
        <Route exact path="/contactus" component={Contact} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route
          exact
          path="/services/websitedevelopment"
          component={WebsiteDevelopment}
        />
        <Route
          exact
          path="/services/mobileappdevelopment"
          component={MobileAppDevelopment}
        />
        <Route
          exact
          path="/services/searchengineoptimization"
          component={SearchEngineOptimization}
        />
        <Route
          exact
          path="/services/graphicdesigning"
          component={GraphicDesigning}
        />
        <Route
          exact
          path="/services/brandmanagement"
          component={BrandManagement}
        />
        <Route
          exact
          path="/services/digitalmarketing"
          component={DigitalMarketing}
        />
        <Route component={NotFound} />
      </Switch>
      <Footer />
      <TopButton />
    </>
  );
};

export default AppRouter;
