import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faAd,
  faBullhorn,
  faLink,
  faSearch,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Contact, HeaderImage } from "../../Components";
import { Helmet } from "react-helmet";

const DigitalMarketing = () => {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.querySelector(".wipe_right_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_right_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_right_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".wipe_left_one").getBoundingClientRect().top -
          window.innerHeight <
        -100
      ) {
        document.querySelector(".wipe_left_one")?.classList.add("visible");
      } else {
        document.querySelector(".wipe_left_one")?.classList.remove("visible");
      }

      if (
        document.querySelector(".our_web_services_row").getBoundingClientRect()
          .top -
          window.innerHeight <
        -100
      ) {
        document
          .querySelector(".our_web_services_row")
          ?.classList.add("visible");
      } else {
        document
          .querySelector(".our_web_services_row")
          ?.classList.remove("visible");
      }
    };
  });

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="The need to have an online presence for any company is extremely fundamental. Whatever the size of the company is or how amazing its internet presence is, the ability to communicate with its customers or not is a major issue. That's where our digital marketing experts assist you by providing only the highest quality, carefully thought-through marketing strategies. Discuss your goals in marketing with our experts in digital marketing now. Our goal is to help you make the most of the power of your online presence in order to increase the number of visitors, attract new customers, and boost sales."
        />
        <meta
          name="keywords"
          content="Mellontical Solutions, social media marketing, sales campaigns, search egnine optimization, link building, social media advertising, google tools, social media, software house, softwares, tech solutions, software company, website, mobile app, web app, software houses in karachi, graphic designing, brand management, digital marketing, search engine optimization, seo, website development, mobile app development, best software houses"
        />
        <title>
          Digital Marketing | Mellontical Solutions | Software Company
        </title>
      </Helmet>
      <HeaderImage
        headerContent="digital marketing"
        headerImage="/images/digitalmarketing.jpg"
      />
      <div className="container">
        <div className="one_service_row">
          <div className="one_service_col wipe_right_one">
            <img src="/images/digitalservice.jpg" loading="lazy" alt="digital marketing" />
          </div>
          <div className="one_service_col wipe_left_one">
            <p>
              The need to have an online presence for any company is extremely
              fundamental. Whatever the size of the company is or how amazing
              its internet presence is, the ability to communicate with its
              customers or not is a major issue. That's where our digital
              marketing experts assist you by providing only the highest
              quality, carefully thought-through marketing strategies. Discuss
              your goals in marketing with our experts in digital marketing now.
              Our goal is to help you make the most of the power of your online
              presence in order to increase the number of visitors, attract new
              customers, and boost sales.
            </p>
          </div>
        </div>
        <div className="web_services">
          <h1 className="website_service_heading">
            digital marketing services
          </h1>
          <div className="our_web_services_row">
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faFacebookF} className="card_icon" />
                </div>
                <h3>social media marketing</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faBullhorn} className="card_icon" />
                </div>
                <h3>sales campaigns</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faSearch} className="card_icon" />
                </div>
                <h3>search engine marketing</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faLink} className="card_icon" />
                </div>
                <h3>link building</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faAd} className="card_icon" />
                </div>
                <h3>social media advertising</h3>
              </div>
            </div>
            <div className="our_web_services_col">
              <div className="web_service_card">
                <div className="icon">
                  <FontAwesomeIcon icon={faShareAlt} className="card_icon" />
                </div>
                <h3>google tools, social media</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default DigitalMarketing;
